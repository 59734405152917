/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { Spinner, SpinnerSize } from "@fluentui/react/";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { datesGenerator } from "dates-generator";
import moment from "moment";
import "moment/locale/da";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";

// Import hooks
import { useBodyClass } from "../../../../hooks";

// Import actions
import {
  getHolidayList,
  setDarkOverlay,
} from "../../../../redux/app/app.actions";
import {
  getMachines,
  getTeams,
  selectNewDate,
  setFilter,
  updateMachine,
} from "../../../../redux/machines/machines.actions";
import { saveMessage } from "../../../../redux/message/message.actions";
import {
  createPlan,
  getPlans,
  updatePlan,
} from "../../../../redux/plan/plan.actions";
import { getPrebookings } from "../../../../redux/prebooking/prebooking.actions";
import { getStaffs } from "../../../../redux/user/user.actions";

// Import constants
import {
  CALENDAR_PAGE,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  CRM_DK,
  crmSpecialStatus,
  ignoreStationOptionsList,
  months,
  workingRoleOptions,
} from "../../../../utils/constants";

// Import utils
import {
  calculateFinalDates,
  checkTimeOverlap,
  findStaffBarVariant,
  findStaffSchedule,
  findYearOfDates,
  getCalendarHoliday,
  getLastDate,
  getMonday,
  hasEditRight,
  removeFirstWeek,
  findStaffIndexAndCounter,
  startCalendarFromMonth,
  startCalendarFromSelectedWeek,
  idGenerator,
} from "../../../../utils/utils";

// Import components
import { ItemContainer, TeamConfirmationDialog } from "../../../common";
import {
  CalendarControlsLeft,
  CalendarControlsRight,
  CalendarHeaderLeft,
  CalendarHeaderRight,
  DayLabel,
  MonthLabel,
  WeekLabel,
} from "../../../common/calendar";
import CRMOverlay from "../../../common/crm/CRMOverlay";
import CustomDraggableList from "../../../common/dragdrop/CustomDraggableList";
import ScreenSizeAndPosition from "../../../common/ScreenSizeAndPosition";
import SearchOverlay from "../../../common/search/SearchOverlay";
import MachineServiceItem from "../../machines/MachineServiceItem";
import PrebookingBubble from "../../prebooking/PrebookingBubble";
import { StaffVacationItem } from "../../staff/index";
import { PlanOverlay, PrebookingOverlay } from "../planOverlay";
import { Prebooking, Project, StaffBar } from ".";
import BarDrawer from "./BarDrawer";
import PlanPlaceholder from "./PlanPlaceholder";
import MachineServiceItemMini from "../../machines/MachineServiceItemMini";
import { getStringifiedMachine } from "../../../../redux/machines/machine.selector";

import { MutationCache } from "react-query";
import { getCrewServiceSchedule } from "../../../../utils/service";
import StaffMachineServiceItem from "../../staff/StaffMachineServiceItem";
import { getServiceText } from "../../../../utils/mapping";
import DispositionStickyControl from "../../DispositionStickyControl";

const mutationCache = new MutationCache({
  onError: (error) => {
    console.log(error);
  },
  onSuccess: (data) => {
    console.log(data);
  },
});

// https://dev.to/aibrahim3546/creating-a-custom-calendar-in-react-from-scratch-1hej
// https://gist.github.com/aibrahim3546/dc68f9525f0f206ff08b1d09ee3adab5
const DispositionCalendar = ({ createPlan, updatePlan }) => {
  const showSidebar = useSelector((state) => state.app.showSidebar);
  const { showInactiveProject } = useSelector((state) => state.app);

  const {
    displayWeekView,
    selectedDayOnCalendar,
    displayStaffOnPlanning,
    displayMachineServiceOnPlanning,
  } = useSelector((state) => state.machine);

  const { weeksShown } = useSelector((state) => state.app);

  const filter = useSelector((state) => state?.machine?.filter);
  const { techDepartment } = useSelector((state) => state?.machine?.filter);
  const {
    techArea,
    selectedRegion,
    machineNames,
    staffNames,
    projectNames,
    selectedMachineSizeGroup,
    showStaffInMachines,
  } = useSelector((state) => state?.machine?.filter?.[techDepartment]);
  const classNames = mergeStyleSets({
    root: {
      minHeight: "100%",
      display: "flex",
      flexDirection: "row",
    },
    leftContainer: {
      width: 250,
      position: "sticky",
      zIndex: 1002,
      top: 0,
      left: showSidebar ? 104 : 0,
      background: "rgb(241, 241, 241)",
      transition: "all 0.5s ease",
    },
    machineAndCalendarContainer: {
      // width: "100%",
      // minHeight: "100%",
      display: "flex",
      justifyContent: "flex-start",
      background: "rgb(241, 241, 241)",
    },
    machineContainer: {
      width: 250,
      // minHeight: "100%",
      marginLeft: 0,
      marginTop: displayStaffOnPlanning ? (showStaffInMachines ? 37 : 67) : 70,
      background: "#f1f1f1",
    },
    calendarContainer: {
      minHeight: "100%",
      // width: 300,
      marginLeft: showSidebar ? 104 : 0,
      transition: "all 0.5s ease",
    },
    monthText: {
      fontSize: 26,
      fontWeight: "bold",
      textAlign: "center",
    },
    days: {
      display: "flex",
      justifyContent: "flex-start",
    },
    dayOfWeeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8,
    },
    weeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 4,
    },
    week: {
      minWidth: 175,
      height: 20,
      background: "#E7E7E7",
      fontSize: 11,
      lineHeight: 20,
      borderRadius: 10,
    },
    stickyHeader: {
      // position: -webkit-sticky; /* Safari */
      // width: 4890,
      position: "sticky",
      top: 0,
      display: "inline-block",
      // minWidth: "calc(100vw - 150px)",
      background: "#F1F1F1",
      zIndex: 1001,
    },
    teamName: {
      fontWeight: "bold",
      textTransform: "uppercase",
      height: 50,
      display: "flex",
      alignItems: "flex-end",
      marginLeft: 15,
      marginBottom: 10,
    },
  });
  useBodyClass(`home`);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const { barDrawer } = useSelector((state) => state.app);
  const { planPlaceholder } = useSelector((state) => state.app);
  const currentUserId = useSelector((state) => state?.user?.user?.userId);

  const calendarStartFrom =
    useSelector(
      (state) => state?.user?.user?.defaultFilter?.customFilters
    )?.find((item) => item.key === "calendarStartFrom")?.text || "month";
  const calendarStepHeight = displayStaffOnPlanning
    ? CALENDAR_STAFFVIEW_STEP_HEIGHT
    : CALENDAR_STEP_HEIGHT;
  /*
        Task 835: section Fag Ramme, Fag InSitu, Fag Geo with filter Fag Udlejning
        https://dev.azure.com/kodebaze/Aarsleff/_boards/board/t/Aarsleff%20Team/Issues/?workitem=835
    */
  let transformedSelectedRegion = selectedRegion;
  if (selectedRegion?.includes(999))
    transformedSelectedRegion = [
      ...selectedRegion.filter((region) => region !== 999),
      201,
      202,
      203,
    ];
  const staffRole = useSelector(
    (state) => state?.machine?.filter?.[techDepartment].staffRole
  );
  const { openSearchOverlay } = useSelector((state) => state.app);

  const [dates, setDates] = useState([]);

  const recalculatedDates = useMemo(() => {
    if (dates?.length && Array.isArray(dates) && selectedDayOnCalendar) {
      let finalDates = JSON.parse(JSON.stringify(dates));

      if (calendarStartFrom === "week") {
        finalDates = startCalendarFromSelectedWeek(
          selectedDayOnCalendar,
          finalDates
        );

        finalDates.splice(weeksShown, finalDates.length - weeksShown);

        return finalDates;
      }

      return dates;
    }

    return [];
  }, [dates, calendarStartFrom, weeksShown, selectedDayOnCalendar]);

  const [currentCalendar, setCalendar] = useState({});

  const calendar = useMemo(() => {
    if (
      currentCalendar?.end &&
      Array.isArray(recalculatedDates) &&
      recalculatedDates?.length
    ) {
      const endDate = getLastDate(recalculatedDates);
      const end = new Date(endDate.year, endDate.month, endDate.date);

      return {
        ...currentCalendar,
        end,
      };
    }

    return currentCalendar;
  }, [currentCalendar, recalculatedDates]);

  const [plansData, setPlansData] = useState([]);
  const [calendarStepWidth, setCalendarStepWidth] = useState(
    displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
  );
  const [machineTeamList, setMachineTeamList] = useState([]);
  const [showModal, setshowModal] = useState(0);
  const [projectUpdate, setProjectUpdate] = useState(0);
  const [disableDragMachine, setDisableDragMachine] = useState(false);
  const [machineListLoading, setMachineListLoading] = useState(false);

  // List of posssible places where the plan can be placed
  const [planPlaceholderList, setPlanPlaceholderList] = useState([]);

  const { data: machinesData } = useQuery("machines", dispatch(getMachines));

  // const { data: allPlansData, refetch: refetchPlans } = useQuery('plans', () => dispatch(getPlans(new Date(selectedDayOnCalendar).getFullYear())));

  const fetchPlansQueryKey = useMemo(
    () => [
      "plans-" +
        moment(new Date(selectedDayOnCalendar)).format("YYYY-M-D") +
        "-" +
        techDepartment,
      showInactiveProject,
    ],
    [selectedDayOnCalendar, techDepartment, showInactiveProject]
  );

  const { data: allPlansData, refetch: refetchPlans } = useQuery(
    fetchPlansQueryKey,
    () => {
      if (dates.length) {
        const startDate = dates[0][0];
        const endDate = getLastDate(dates);

        const startCalendar = new Date(
          startDate.year,
          startDate.month,
          startDate.date
        ).toISOString();
        const endCalendar = new Date(
          endDate.year,
          endDate.month,
          endDate.date
        ).toISOString();
        return dispatch(
          getPlans(
            startDate.year,
            startCalendar,
            endCalendar,
            techDepartment,
            showInactiveProject
          )
        );
      }
      return [];
    },
    { staleTime: 6000 * 5, refetchOnWindowFocus: false }
  );

  const refetchPlansData = useCallback(() => {
    queryClient.invalidateQueries([fetchPlansQueryKey]);
    refetchPlans();
  }, [refetchPlans]);

  const { data: staffsData } = useQuery("staffs", dispatch(getStaffs));
  const { data: prebookingsData } = useQuery("prebookings", () =>
    getPrebookings(null, null, showInactiveProject)(dispatch)
  );

  const { data: teamsData } = useQuery("teams", () => dispatch(getTeams()));
  const { mutate: updateMachineService } = useMutation(
    (machine) => dispatch(updateMachine(machine)),
    {
      onSuccess: () => {
        if (machinesData) {
          queryClient.invalidateQueries("machines");
        }
      },
    }
  );

  const createPlanMutation = useMutation((plan) => createPlan(plan.data), {
    onMutate: async (updates) => {
      onProjectOptimisticUpdate(updates.data);
    },
  });

  const updateMachineRequirementMutation = useMutation((plan) =>
    updatePlan(plan.data)
  );

  useEffect(() => {
    return () => {
      queryClient.cancelQueries("machines");
      // queryClient.cancelQueries(fetchPlansQueryKey);
      queryClient.cancelQueries("staffs");
      queryClient.cancelQueries("prebookings");
      queryClient.cancelQueries("teams");
    };
  }, [queryClient]);

  const holidayRef = useRef(null);

  const filterPlansData = () => {
    const filteredPlansData = allPlansData.filter(
      (plan) =>
        plan.projectNo &&
        plan.projectName &&
        plan.section &&
        (!plan["inactive"] || showInactiveProject) &&
        plan.projectName
          .toLowerCase()
          .includes(projectNames?.toLowerCase() || "")
    );
    // .map((el) => {
    //   const reqs = el.machineRequirements.filter(
    //     (req) => req.crmpaaFleetplanner === CRM_DK || !req.crmpaaFleetplanner
    //   );

    //   el.machineRequirements = reqs;

    //   return el;
    // });

    return filteredPlansData;
  };

  // Get all region of plansData
  useEffect(() => {
    if (allPlansData && recalculatedDates.length) {
      const filteredPlansData = filterPlansData();

      const plansDataRegion = [
        ...new Set(filteredPlansData.map((plan) => plan.section)),
      ];

      // Get all machine size of current techDepartment
      let machinesSize = [];
      machinesData?.forEach((machine) => {
        if (
          machine.techDepartments?.includes(techDepartment) &&
          machinesSize.indexOf(machine["sizeGroup"]) === -1
        )
          machinesSize.push(machine["sizeGroup"]);
      });
      machinesSize = machinesSize.sort((a, b) => a - b);
      dispatch(
        setFilter({
          [techDepartment]: {
            availableRegion: plansDataRegion,
            selectedRegion:
              selectedRegion && selectedRegion?.length
                ? selectedRegion
                : plansDataRegion,
            availableMachineSizeGroup: machinesSize,
            selectedMachineSizeGroup:
              selectedMachineSizeGroup && selectedMachineSizeGroup?.length
                ? selectedMachineSizeGroup
                : machinesSize,
          },
        })
      );
      setPlansData(filteredPlansData);
    }
  }, [
    allPlansData,
    recalculatedDates,
    techDepartment,
    projectNames,
    showInactiveProject,
  ]);

  // Add icon to resize cursor
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      const resizeDivList = document.getElementsByClassName("react-draggable");

      for (let index = 0; index < resizeDivList.length; index++) {
        const resizeDiv = resizeDivList[index].lastChild;
        if (resizeDiv.firstChild && resizeDiv.lastChild) {
          resizeDiv.firstChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.firstChild.style.left = "-14px";
          resizeDiv.lastChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.lastChild.style.right = "3px";
        }
      }
    }, 500);

    return () => {
      clearTimeout(timeoutRef);
    };
  });

  const filterTarget = (
    machinesData,
    staffsData,
    techDepartment,
    techArea,
    getFilteredMachine = false
  ) => {
    let filterData = [];
    if (
      displayStaffOnPlanning &&
      !getFilteredMachine &&
      Array.isArray(staffsData)
    ) {
      filterData = techDepartment
        ? staffsData.filter(
            (staff) =>
              !staff.inactive &&
              techDepartment === staff.techDepartment &&
              (staff.firstName + " " + staff.lastName)
                .toLowerCase()
                .includes(staffNames?.toLowerCase() || "") &&
              techArea?.includes(
                staff.techArea ||
                  ignoreStationOptionsList.includes(techDepartment)
              )
          )
        : staffsData;
      filterData = filterData.filter((staff) =>
        staffRole.some(
          (role) =>
            role.isOn && staff.workingRole.replace("-1", "").includes(role.key)
        )
      );
    }
    if (
      (!displayStaffOnPlanning || getFilteredMachine) &&
      Array.isArray(machinesData) &&
      recalculatedDates.length &&
      prebookingsData
    ) {
      const startDate = recalculatedDates[0][0];
      const endDate = getLastDate(recalculatedDates);
      const startCalendar = new Date(
        startDate.year,
        startDate.month,
        startDate.date
      );
      const endCalendar = new Date(endDate.year, endDate.month, endDate.date);

      const prebookingTemp = prebookingsData.filter(
        (booking) =>
          booking.projectName
            .toLowerCase()
            .includes(projectNames?.toLowerCase() || "") &&
          ((new Date(booking.start) <= endCalendar &&
            new Date(booking.end) >= startCalendar) ||
            (new Date(booking.start) >= startCalendar &&
              new Date(booking.start) <= endCalendar))
      );

      filterData = techDepartment
        ? machinesData
            .filter(
              (machine) =>
                !machine.inactive &&
                machine.techDepartments?.includes(techDepartment) &&
                (techArea.some((key) =>
                  machine.techAreas.replace("-1", "").includes(key)
                ) ||
                  ignoreStationOptionsList.includes(techDepartment)) &&
                machine.machineName
                  .toLowerCase()
                  .includes(machineNames?.toLowerCase() || "") &&
                selectedMachineSizeGroup?.includes(machine.sizeGroup) &&
                (projectNames
                  ? plansData.find((plan) =>
                      plan.machineRequirements.find((req) =>
                        req.machines.find(
                          (currentMachine) =>
                            currentMachine.machineId === machine.machineId &&
                            ((new Date(currentMachine.start) <= endCalendar &&
                              new Date(currentMachine.end) >= startCalendar) ||
                              (new Date(currentMachine.start) >=
                                startCalendar &&
                                new Date(currentMachine.start) <= endCalendar))
                        )
                      )
                    ) ||
                    prebookingTemp.find(
                      (currentMachine) =>
                        currentMachine.machineId === machine.machineId
                    )
                  : true)
            )
            .sort((a, b) => a.sortIndex - b.sortIndex)
        : machinesData;
    }
    return filterData;
  };

  const filterStaffs = (filteredTargetData) => {
    const result = [];
    if (!recalculatedDates.length) return [];
    const startDate = recalculatedDates[0][0];
    const endDate = getLastDate(recalculatedDates);
    const startCalendar = new Date(
      startDate.year,
      startDate.month,
      startDate.date
    );
    const endCalendar = new Date(endDate.year, endDate.month, endDate.date);
    if (displayStaffOnPlanning && plansData.length > 0) {
      plansData.forEach((plan) => {
        if (!plan.inactive || showInactiveProject) {
          plan.machineRequirements.forEach(
            (machineRequirement, indexMachineRequirement) => {
              const machines = machineRequirement.machines;

              [
                machineRequirement["drivers"],
                machineRequirement["workers"],
                machineRequirement["managers"],
              ].forEach((array) => {
                array.forEach((item) => {
                  if (
                    filteredTargetData.some(
                      (item2) => item2.userId === item.id
                    ) &&
                    (item.firstName + " " + item.lastName)
                      .toLowerCase()
                      .includes(staffNames?.toLowerCase() || "") &&
                    ((new Date(item.start) <= endCalendar &&
                      new Date(item.end) >= startCalendar) ||
                      (new Date(item.start) >= startCalendar &&
                        new Date(item.start) <= endCalendar))
                    // &&
                    // machines.some((el) => el.machineId === item.machineId)
                  ) {
                    let replicateStaff = null;
                    for (let i = result.length - 1; i >= 0; i--) {
                      if (result[i].id === item.id) {
                        replicateStaff = result[i];
                        break;
                      }
                    }
                    const index = !replicateStaff
                      ? 0
                      : replicateStaff.index + 1;

                    const serviceSchedules = getCrewServiceSchedule(
                      item.id,
                      machinesData || []
                    );

                    result.push({
                      ...item,
                      starfVacations: !replicateStaff
                        ? item.starfVacations
                        : null,
                      serviceSchedules,
                      personResponsbible: plan?.personResponsbible?.name,
                      isQuotation: plan.isQuotation,
                      projectNo: plan.projectNo,
                      projectId: plan.projectId,
                      projectName: plan.projectName,
                      color: plan.color,
                      indexMachineRequirement,
                      index,
                      inactive: plan.inactive,
                      machineId: item.machineId,
                      machineName: item.machineName,
                    });
                  }
                });
              });
            }
          );
        }
      });
    }
    filteredTargetData.forEach((item) => {
      const index = result.findIndex((item2) => item2.id === item.userId);
      if (index === -1 && item.starfVacations) {
        result.push({ ...item, id: item.userId, index: 0 });
      }
    });
    return result;
  };

  const createHorizontalPositionMap = (dates) => {
    const result = {};
    const datePositionCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        //date: 1, month: 2, year: 2021
        let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
          dates[i][j]["date"]
        }`;
        result[key] = counter;
        datePositionCorrespondence[counter] = moment(key, "YYYY-M-D").format(
          "YYYY-MM-DD[T]HH:mm:ss.SSS"
        );
        counter++;
      }
    }
    result["end"] = --counter;
    result["positionsToDates"] = datePositionCorrespondence;
    return result;
  };

  const createVerticalPositionMap = (machines) => {
    const result = {};
    const positionIdCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < machines.length; i++) {
      if (displayStaffOnPlanning) {
        const tempListWithSameId = filteredStaffs.filter(
          (staff) => staff.id === machines[i]["userId"]
        );
        if (tempListWithSameId.length > 1) {
          const { positionAndCounter, currentCounter } =
            findStaffIndexAndCounter(tempListWithSameId, counter, true);
          counter = currentCounter;

          result[machines[i]["userId"]] = positionAndCounter;
        } else {
          result[machines[i]["userId"]] = { 0: counter };
        }
        let listOfPlan = [];
        const staffRoles = machines[i].workingRole.split(";");
        staffRoles.forEach((roleKey) => {
          const currentRole = workingRoleOptions.find(
            (role) => role.key === parseInt(roleKey)
          )?.name;
          if (currentRole) {
            listOfPlan = [
              ...listOfPlan,
              ...findStaffSchedule(
                machines[i]["userId"],
                null,
                currentRole,
                allPlansData?.filter(
                  (item) => !crmSpecialStatus[item.crmProjectStatusCode]
                )
              ),
            ];
          }
        });
        const listOfVacation = machines[i].starfVacations || [];
        positionIdCorrespondence[counter * calendarStepHeight] = {
          id: machines[i]["userId"],
          name: `${machines[i]["firstName"]} ${machines[i]["lastName"]}`,
          image: machines[i]["imageUrl"],
          listOfPlan,
          listOfVacation,
          workingRoles: machines[i].workingRole,
        };
      } else {
        const listOfPlan = [];
        const listOfService = [];
        plansData
          ?.filter((item) => !crmSpecialStatus[item.crmProjectStatusCode])
          .forEach((plan) => {
            !plan.inactive &&
              plan.machineRequirements.forEach(
                (machineRequirement, machineRequirementIndex) => {
                  machineRequirement.machines.forEach(
                    (machine, machineIndex) => {
                      if (machine.machineId === machines[i]["machineId"]) {
                        const getStart = (start) => {
                          if (start && !start.includes("Z")) {
                            return new Date(start.split("T")[0]).toISOString();
                          }

                          return start;
                        };

                        const getEnd = (end) => {
                          if (end && !end.includes("Z")) {
                            return new Date(end.split("T")[0]).toISOString();
                          }

                          return end;
                        };

                        const machineStart = getStart(machine.start);
                        let start = moment(machineStart.slice(0, 10)).valueOf();

                        const machineEnd = getEnd(machine.end);
                        let end = moment(machineEnd.slice(0, 10)).valueOf();
                        let startCalendar = moment(
                          Object.keys(horizontalPositionMap).find(
                            (key) => horizontalPositionMap[key] === 0
                          )
                        ).valueOf();
                        let endCalendar = moment(
                          Object.keys(horizontalPositionMap).find(
                            (key) =>
                              horizontalPositionMap[key] ===
                              horizontalPositionMap["end"]
                          )
                        ).valueOf();
                        let startDate = moment(machineStart)
                          .utcOffset(0)
                          .format("YYYY-M-D");
                        let endDate = moment(machineEnd)
                          .utcOffset(0)
                          .format("YYYY-M-D");

                        let tempStart =
                          start < startCalendar
                            ? 0
                            : horizontalPositionMap[startDate];
                        let tempEnd =
                          end >= endCalendar
                            ? horizontalPositionMap[endDate] ||
                              horizontalPositionMap.end + 1
                            : horizontalPositionMap[endDate];
                        if (
                          typeof tempStart !== "undefined" &&
                          typeof tempEnd !== "undefined"
                        ) {
                          listOfPlan.push({
                            start: tempStart,
                            end: tempEnd,
                            machineRequirementIndex,
                            machineId: machine.machineId,
                            machineIndex,
                            projectId: plan.projectId,
                            endDate: endDate,
                            startDate: startDate,
                          });
                        }
                      }
                    }
                  );
                }
              );
          });
        prebookingsData?.forEach((prebooking) => {
          if (prebooking.machineId === machines[i]["machineId"]) {
            let start = moment(prebooking.start.slice(0, 10)).valueOf();
            let end = moment(prebooking.end.slice(0, 10)).valueOf();
            let startCalendar = moment(
              Object.keys(horizontalPositionMap).find(
                (key) => horizontalPositionMap[key] === 0
              )
            ).valueOf();
            let endCalendar = moment(
              Object.keys(horizontalPositionMap).find(
                (key) =>
                  horizontalPositionMap[key] === horizontalPositionMap["end"]
              )
            ).valueOf();
            let startDate = moment(prebooking.start)
              .utcOffset(0)
              .format("YYYY-M-D");
            let endDate = moment(prebooking.end)
              .utcOffset(0)
              .format("YYYY-M-D");
            let tempStart =
              start < startCalendar ? 0 : horizontalPositionMap[startDate];
            let tempEnd =
              end >= endCalendar
                ? horizontalPositionMap[endDate] ||
                  horizontalPositionMap.end + 1
                : horizontalPositionMap[endDate];

            if (
              typeof tempStart !== "undefined" &&
              typeof tempEnd !== "undefined"
            ) {
              listOfPlan.push({
                start: tempStart,
                end: tempEnd,
              });
            }
          }
        });
        machinesData?.forEach((machine) => {
          if (machine.machineId === machines[i]["machineId"]) {
            machine.machineService?.forEach((service) => {
              let start = moment(service.start.slice(0, 10)).valueOf();
              let end = moment(service.end.slice(0, 10)).valueOf();
              let startCalendar = moment(
                Object.keys(horizontalPositionMap).find(
                  (key) => horizontalPositionMap[key] === 0
                )
              ).valueOf();
              let endCalendar = moment(
                Object.keys(horizontalPositionMap).find(
                  (key) =>
                    horizontalPositionMap[key] === horizontalPositionMap["end"]
                )
              ).valueOf();
              let startDate = moment(service.start)
                .utcOffset(0)
                .format("YYYY-M-D");
              let endDate = moment(service.end).utcOffset(0).format("YYYY-M-D");
              let tempStart =
                start < startCalendar ? 0 : horizontalPositionMap[startDate];
              let tempEnd =
                end >= endCalendar
                  ? horizontalPositionMap[endDate] ||
                    horizontalPositionMap.end + 1
                  : horizontalPositionMap[endDate];

              if (
                typeof tempStart !== "undefined" &&
                typeof tempEnd !== "undefined"
              ) {
                listOfService.push({
                  start: tempStart,
                  end: tempEnd,
                });
              }
            });
          }
        });
        result[machines[i]["machineId"]] = counter;
        positionIdCorrespondence[counter * calendarStepHeight] = {
          id: machines[i]["machineId"],
          name: machines[i]["machineName"],
          image: machines[i]["imageUrl"],
          listOfPlan,
          listOfService,
        };
      }
      counter++;
    }
    result["positionsToIds"] = positionIdCorrespondence;
    return result;
  };

  const filterUnplannedProjects = (plansData) => {
    if (!recalculatedDates.length) return [];
    // const startDate = dates[0][0];
    // const endDate = getLastDate(dates);
    // const startCalendar = new Date(startDate.year, startDate.month, startDate.date);
    // const endCalendar = new Date(endDate.year, endDate.month, endDate.date);
    if (Array.isArray(plansData)) {
      let result = [];
      let counter = filteredTargetData.length;
      for (let i = 0; i < plansData.length; i++) {
        if (!plansData[i].inactive || showInactiveProject) {
          for (let j = 0; j < plansData[i]["machineRequirements"].length; j++) {
            let temp = plansData[i]["machineRequirements"][j];
            if (
              !temp["machines"].length &&
              temp.techDepartment === techDepartment &&
              transformedSelectedRegion?.includes(plansData[i]["section"]) &&
              plansData[i].projectName
                .toLowerCase()
                .includes(projectNames?.toLowerCase() || "")
              // &&
              // new Date(temp.start) <= endCalendar &&
              // new Date(temp.end) >= startCalendar
            ) {
              temp["projectId"] = plansData[i]["projectId"];
              temp["projectName"] = plansData[i]["projectName"];
              temp["position"] = counter;
              temp["planIndex"] = i;
              temp["machineRequirementIndexInPlan"] = j;
              temp["machineIndexInPlan"] = 0;
              result.push(temp);
              counter++;
            }
          }
        }
      }
      return result;
    } else return [];
  };

  let heightOfBackgroundRef = useRef(0);

  const getStaffDisplayedInMachines = (machinesData, verticalPositionMap) => {
    let staffInMachines = [];
    let staffsWithPosition = [];
    const startDate = recalculatedDates[0][0];
    const endDate = getLastDate(recalculatedDates);
    const startCalendar = new Date(
      startDate.year,
      startDate.month,
      startDate.date
    );
    const endCalendar = new Date(endDate.year, endDate.month, endDate.date);

    const staffDisplayed = Object.values(
      verticalPositionMap.positionsToIds
    ).reduce((acc, curr) => [...acc, curr.id], []);
    const staffsHavePlan = [];
    let yPosition = 0;
    let heightOfMachineName = 60;
    if (showStaffInMachines) {
      const machinesList = filterTarget(
        machinesData,
        staffsData,
        techDepartment,
        techArea,
        true
      );
      machinesList.forEach((machine) => {
        const currentData = {
          machineId: machine.machineId,
          machineName: machine.machineName,
          y: yPosition === 0 ? yPosition : yPosition + 30,
          staffs: [],
        };
        const plansContainCurrentMachine = plansData.filter((plan) =>
          plan["machineRequirements"].some((requirement) =>
            requirement.machines.some(
              (machinePlan) => machinePlan.machineId === machine.machineId
            )
          )
        );

        plansContainCurrentMachine.forEach((plan) => {
          plan.machineRequirements.forEach((req) => {
            if (
              req.machines.some(
                (machinePlan) => machinePlan.machineId === machine.machineId
              )
            ) {
              ["drivers", "managers", "workers"].forEach((key) => {
                if (req[key] && req[key].length)
                  req[key].forEach((staff) => {
                    if (
                      staffDisplayed.includes(staff.id) &&
                      ((new Date(staff.start) <= endCalendar &&
                        new Date(staff.end) >= startCalendar) ||
                        (new Date(staff.start) >= startCalendar &&
                          new Date(staff.start) <= endCalendar)) &&
                      staff.machineId === machine.machineId
                    ) {
                      if (!currentData.staffs.length && !staffInMachines.length)
                        yPosition += 28;
                      else if (!currentData.staffs.length)
                        yPosition += heightOfMachineName;
                      if (
                        currentData.staffs.findIndex(
                          (item) => item.id === staff.id
                        ) === -1
                      ) {
                        const barVariant = findStaffBarVariant(
                          verticalPositionMap,
                          staff.id
                        );
                        currentData.staffs.push({
                          id: staff.id,
                          y: yPosition,
                          info: staff,
                          barVariant,
                        });
                        staffsWithPosition.push({ id: staff.id, y: yPosition });
                        yPosition +=
                          barVariant * CALENDAR_STAFFVIEW_STEP_HEIGHT;
                        staffsHavePlan.push(staff.id);
                      }
                    }
                  });
              });
            }
          });
        });
        if (!currentData.staffs.length && !staffInMachines.length)
          yPosition += heightOfMachineName - 30;
        else if (!currentData.staffs.length) yPosition += heightOfMachineName;

        const data = {
          ...currentData,
          machineService:
            machinesData.find((el) => currentData.machineId === el.machineId)
              ?.machineService || [],
        };

        staffInMachines.push(data);
      });
      const staffsNoPlan = staffDisplayed
        .filter((staffId) => ![...new Set(staffsHavePlan)].includes(staffId))
        .map((staffId) => staffsData.find((item) => item.userId === staffId));

      const staffsNoPlanContainer = {
        machineId: idGenerator(),
        machineName: "Not in machines",
        y: yPosition === 0 ? yPosition : yPosition + 30,
        staffs: staffsNoPlan.map((staff, index) => {
          if (!index && !staffInMachines.length) yPosition += 28;
          else if (!index) yPosition += heightOfMachineName;
          const barVariant = findStaffBarVariant(
            verticalPositionMap,
            staff.userId
          );
          const result = {
            id: staff.userId,
            y: yPosition,
            info: staff,
            barVariant,
          };
          staffsWithPosition.push({ id: staff.userId, y: yPosition });
          yPosition += barVariant * CALENDAR_STAFFVIEW_STEP_HEIGHT;
          return result;
        }),
      };
      staffInMachines.push(staffsNoPlanContainer);
    } else {
      const staffDisplayedAsc = Object.values(
        verticalPositionMap.positionsToIds
      )
        .reduce((acc, curr) => [...acc, { id: curr.id, name: curr.name }], [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => item.id);
      const staffsBarData = [];
      staffDisplayedAsc.forEach((staffId) => {
        plansData.forEach((plan) => {
          plan.machineRequirements.forEach((req) => {
            ["drivers", "managers", "workers"].forEach((key) => {
              if (req[key] && req[key].length) {
                req[key].forEach((staff) => {
                  if (
                    staffId === staff.id &&
                    ((new Date(staff.start) <= endCalendar &&
                      new Date(staff.end) >= startCalendar) ||
                      (new Date(staff.start) >= startCalendar &&
                        new Date(staff.start) <= endCalendar))
                  ) {
                    const staffStartDate = new Date(staff.start);
                    const staffEndDate = new Date(staff.end);

                    if (
                      staffsBarData.findIndex(
                        (item) => item.id === staff.id
                      ) === -1 &&
                      staffStartDate >= new Date(req.start) &&
                      staffEndDate <= new Date(req.end)
                    ) {
                      const barVariant = findStaffBarVariant(
                        verticalPositionMap,
                        staff.id
                      );
                      staffsBarData.push({
                        id: staff.id,
                        y: yPosition,
                        info: staff,
                        barVariant,
                      });
                      staffsWithPosition.push({ id: staff.id, y: yPosition });
                      yPosition += barVariant * CALENDAR_STAFFVIEW_STEP_HEIGHT;
                    }
                  }
                });
              }
            });
          });
        });
        if (staffsBarData.findIndex((item) => item.id === staffId) === -1) {
          const barVariant = findStaffBarVariant(verticalPositionMap, staffId);

          staffsBarData.push({
            id: staffId,
            y: yPosition,
            info: staffsData.find((item) => item.userId === staffId),
            barVariant,
          });
          staffsWithPosition.push({ id: staffId, y: yPosition });
          yPosition += barVariant * CALENDAR_STAFFVIEW_STEP_HEIGHT;
        }
      });
      staffInMachines = [
        {
          machineId: idGenerator(),
          machineName: "All staffs",
          y: 0,
          staffs: staffsBarData,
        },
      ];
    }
    heightOfBackgroundRef.current = yPosition;
    return { staffInMachines, staffsWithPosition };
  };

  const filteredTargetData = useMemo(
    () => filterTarget(machinesData, staffsData, techDepartment, techArea),
    [
      plansData,
      displayStaffOnPlanning,
      staffsData,
      machinesData,
      filter,
      prebookingsData,
    ]
  );

  const filteredStaffs = useMemo(
    () => filterStaffs(filteredTargetData),
    [
      filteredTargetData,
      plansData,
      recalculatedDates,
      showInactiveProject,
      machinesData,
    ]
  );

  const calendarState = useSelector(getStringifiedMachine);

  const horizontalPositionMap = useMemo(
    () => createHorizontalPositionMap(recalculatedDates),
    [recalculatedDates]
  );

  const verticalPositionMap = useMemo(
    () => createVerticalPositionMap(filteredTargetData),
    [
      filteredTargetData,
      recalculatedDates,
      horizontalPositionMap,
      plansData,
      prebookingsData,
      filteredStaffs,
    ]
  );

  const renderDependencies = useMemo(() => {
    return (
      calendarState +
      (verticalPositionMap ? JSON.stringify(verticalPositionMap) : "")
    );
  }, [verticalPositionMap, calendarState]);

  const { staffInMachines, staffsWithPosition } = useMemo(
    () =>
      teamsData &&
      verticalPositionMap &&
      displayStaffOnPlanning &&
      staffsData &&
      recalculatedDates.length &&
      plansData.length
        ? getStaffDisplayedInMachines(machinesData, verticalPositionMap)
        : { staffInMachines: [], staffsWithPosition: [] },
    [
      teamsData,
      verticalPositionMap,
      displayStaffOnPlanning,
      recalculatedDates,
      plansData,
    ]
  );

  useEffect(() => {
    if (
      planPlaceholder.active &&
      filteredTargetData &&
      Object.keys(verticalPositionMap.positionsToIds).length &&
      Object.keys(horizontalPositionMap.positionsToDates).length
    ) {
      const { machineSizeGroup, machineType, start, end } = planPlaceholder;
      const suitableMachines = filteredTargetData.filter(
        (item) =>
          item.sizeGroup === machineSizeGroup &&
          item.machineType === machineType
      );
      const listOfPlanHolder = suitableMachines.map((item, index) => ({
        id: `${item.machineId}-${index}`,
        machine: item,
        start,
        end,
      }));
      const availablePlan = listOfPlanHolder.filter((plan) => {
        const machineScheduleData = Object.values(
          verticalPositionMap.positionsToIds
        ).find((item) => item.id === plan.machine.machineId);
        const planStartDate = new Date(plan.start.slice(0.1));
        const planEndDate = new Date(plan.end.slice(0.1));
        const listToCheck = [
          ...machineScheduleData.listOfPlan,
          ...machineScheduleData.listOfService,
        ];
        let overlap = false;
        for (let i = 0; i < listToCheck.length; i++) {
          let itemStartDate = new Date(
            horizontalPositionMap.positionsToDates[listToCheck[i].start].slice(
              0,
              10
            )
          );
          let itemEndDate =
            listToCheck[i].end > horizontalPositionMap.end
              ? new Date(
                  horizontalPositionMap.positionsToDates[
                    horizontalPositionMap.end
                  ].slice(0, 10)
                )
              : new Date(
                  horizontalPositionMap.positionsToDates[
                    listToCheck[i].end
                  ].slice(0, 10)
                );
          const isOverlap = checkTimeOverlap(
            planStartDate,
            planEndDate,
            itemStartDate,
            itemEndDate
          );
          if (isOverlap) {
            overlap = true;
            break;
          }
        }
        return !overlap;
      });
      dispatch(setDarkOverlay(true));
      setPlanPlaceholderList(availablePlan);
    } else {
      setPlanPlaceholderList([]);
    }
  }, [
    planPlaceholder,
    verticalPositionMap,
    horizontalPositionMap,
    filteredTargetData,
  ]);

  const unplannedItems = useMemo(
    () => filterUnplannedProjects(allPlansData),
    [
      allPlansData,
      filteredTargetData,
      selectedRegion,
      recalculatedDates,
      filter,
      showInactiveProject,
    ]
  );

  const getMonthIndicator = (dates) => {
    let components = [];
    let temp = dates[0][0]["month"];
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        if (dates[i][j]["month"] === temp) {
          counter++;
        } else {
          components.push({ length: counter, label: months[temp] });
          counter = 1;
          temp = dates[i][j]["month"];
        }
      }
    }
    components.push({ length: counter, label: months[temp] });
    return components;
  };

  const onClickChooseDate = async (date) => {
    dispatch(selectNewDate(date));
    let array = new Array(displayWeekView ? 12 : 6);

    array[0] = datesGenerator({
      month: date.getMonth(),
      year: date.getFullYear(),
      startingDay: 1,
    });
    for (let i = 1; i < array.length; i++) {
      array[i] = datesGenerator({
        month: array[i - 1].nextMonth,
        year: array[i - 1].nextYear,
        startingDay: 1,
      });
    }

    // This is to allow the user to limit the amount of weeks shown
    // if (calendarStartFrom === "week") {
    //   const { length } = array;

    //   const arrCopy = [];

    //   let weeksCounter = 0;

    //   let weekReached = false;

    //   for (let i = 0; i < length && weeksCounter < weeksShown; i++) {
    //     const { length: datesLength } = array[i].dates;

    //     arrCopy.push({
    //       previousYear: array[i].previousYear,
    //       previousMonth: array[i].previousMonth,
    //       nextYear: array[i].nextYear,
    //       nextMonth: array[i].nextMonth,
    //       dates: [],
    //     });

    //     for (let j = 0; j < datesLength && weeksCounter < weeksShown; j++) {
    //       if (!weekReached) {
    //         const { length: weeksLength } = array[i].dates[j];

    //         for (let m = 0; m < weeksLength; m++) {
    //           const { date: currentDate, month, year } = array[i].dates[j][m];

    //           if (
    //             month === date.getMonth() &&
    //             date.getDate() === currentDate &&
    //             date.getFullYear() === year
    //           ) {
    //             weekReached = true;
    //           }
    //         }
    //       }

    //       arrCopy[i].dates.push(array[i].dates[j]);

    //       if (weekReached) {
    //         weeksCounter++;
    //       }
    //     }
    //   }

    //   array = arrCopy;
    // }

    let { finalDates, start, end } = calculateFinalDates(array);

    if (calendarStartFrom === "month" && !displayWeekView) {
      finalDates[0] = startCalendarFromMonth(date, finalDates[0]);
    }

    // Find the start of calendar if choose to start from selected week
    if (calendarStartFrom === "week") {
      finalDates = startCalendarFromSelectedWeek(date, finalDates);

      finalDates.splice(20, finalDates.length - 20);

      // end =
      //   finalDates[finalDates.length - 1][
      //     finalDates[finalDates.length - 1].length - 1
      //   ];

      const lastDate =
        finalDates[finalDates.length - 1][
          finalDates[finalDates.length - 1].length - 1
        ];

      end = new Date(lastDate.year, lastDate.month, lastDate.date);
    }
    start = new Date(
      finalDates[0][0].year,
      finalDates[0][0].month,
      finalDates[0][0].date
    );

    // Find the holiday of calendar
    async function getDatesWithHolidays() {
      const yearOfDates = findYearOfDates(finalDates);
      let holidays = [];
      if (
        !holidayRef.current ||
        JSON.stringify(yearOfDates) !==
          JSON.stringify(holidayRef.current?.years)
      ) {
        for (const year of yearOfDates) {
          const currentHolidays = await dispatch(getHolidayList(year));
          holidays = [...holidays, ...(currentHolidays?.holidays || [])];
        }
        holidayRef.current = {
          years: yearOfDates,
          holidays: holidays,
        };
      } else {
        holidays = holidayRef.current.holidays;
      }
      finalDates = getCalendarHoliday(finalDates, holidays);
      setDates(finalDates);
    }
    getDatesWithHolidays();
    setDates(finalDates);
    const endDate = getLastDate(finalDates);
    end = new Date(endDate.year, endDate.month, endDate.date);

    setCalendar({
      ...calendar,
      nextMonth: array[array.length - 1].nextMonth,
      nextYear: array[array.length - 1].nextYear,
      previousMonth: array[0].previousMonth,
      previousYear: array[0].previousYear,
      start,
      end,
    });
  };

  useEffect(() => {
    if (recalculatedDates.length) {
      refetchPlans();
    }
  }, [recalculatedDates]);

  useEffect(() => {
    setCalendarStepWidth(
      displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
    );
    onClickChooseDate(getMonday(new Date(selectedDayOnCalendar)));
  }, [
    displayWeekView,
    moment(new Date(selectedDayOnCalendar)).format("YYYY-M-D"),
    weeksShown,
  ]);

  const staffItemContainer = useMemo(() => {
    const list = [];
    if (displayStaffOnPlanning && staffInMachines.length) {
      staffInMachines.forEach(({ machineName, staffs }, machineIndex) => {
        // Only push name to list if showStaffInMachines
        if (showStaffInMachines) {
          list.push(
            <div key={machineIndex} className={classNames.teamName}>
              {machineName}
            </div>
          );
        }
        staffs.forEach((staff, staffIndex) => {
          const staffData = filteredTargetData.find(
            (item) => item.userId === staff.id
          );
          const temp = verticalPositionMap[staff.id];
          let tempList = new Set();
          for (const key in temp) {
            tempList.add(temp[key]);
          }
          tempList = Array.from(tempList);
          let count = 1;
          if (tempList.length >= 2) {
            count *= tempList.length;
          }
          list.push(
            <ItemContainer
              calendarPage={CALENDAR_PAGE.DISPOSITION}
              count={count}
              key={`${staffIndex}-${machineName}-${staffData.userId}`}
              workingRole={staffData.workingRole}
              machineDocumentUrl={staffData.machineDocumentUrl}
              index={`${staffData.userId}-${staffIndex}`}
              name={`${staffData.firstName} ${staffData.lastName ?? ""}`}
              machineId={staffData.machineId}
              techDepartments={staffData.techDepartment.toString()}
              techAreas={staffData.techArea.toString()}
              imageUrl={staffData?.imageUrl}
              isClone={staff.isClone}
              staffId={staffData.userId}
              isPlanningPage
            />
          );
        });
      });
    }
    return list;
  }, [displayStaffOnPlanning, filteredTargetData, verticalPositionMap]);

  const calculateQuantityOfItemContainer = useMemo(() => {
    let count = 0;
    if (displayStaffOnPlanning) {
      for (let i = 0; i < filteredTargetData.length; i++) {
        const temp = verticalPositionMap[filteredTargetData[i].userId];
        let tempList = new Set();
        for (const key in temp) {
          tempList.add(temp[key]);
        }
        tempList = Array.from(tempList);
        for (let j = 0; j < tempList.length; j++) {
          count++;
        }
      }
    }
    return count;
  }, [displayStaffOnPlanning, filteredTargetData, verticalPositionMap]);

  if (!displayStaffOnPlanning) {
    heightOfBackgroundRef.current =
      !displayStaffOnPlanning && displayStaffOnPlanning
        ? calculateQuantityOfItemContainer * CALENDAR_STAFFVIEW_STEP_HEIGHT
        : filteredTargetData.length * calendarStepHeight;
  }

  useEffect(() => {
    let res = [];
    teamsData &&
      teamsData.forEach((t) => {
        if (!t.inactive && t.techDepartments.includes(techDepartment)) {
          if (!res[t.machineId]) {
            res[t.machineId] = {
              machineId: t.machineId,
              machineName: t.machineName,
              teams: [],
            };
          }
          res[t.machineId].teams.push(t);
        }
      });
    setMachineTeamList(Object.values(res));
  }, [techDepartment, teamsData]);

  const getModal = (data) => {
    setshowModal(data);
  };

  const hideModal = () => {
    setshowModal(0);
    setProjectUpdate(0);
  };

  const getProjectUpdate = (data) => {
    setProjectUpdate(data);
  };

  const onProjectDrawOptimistic = async (plan) => {
    const cachedData = queryClient.getQueryData(fetchPlansQueryKey);

    if (cachedData) {
      const index = cachedData.findIndex(
        (item) => item.projectId === plan.projectId
      );

      if (index !== -1) {
        let clone = JSON.parse(JSON.stringify(cachedData));
        clone[index].machineRequirements = plan.machineRequirements;

        mutationCache.clear();

        queryClient.setQueryData(fetchPlansQueryKey, clone);
        queryClient.setQueryData(["plans", dates], clone);
        // queryClient.setQueryData(["plan", planData["projectId"]], planData);

        // queryClient.invalidateQueries(fetchPlansQueryKey);
        // queryClient.invalidateQueries(["plans", dates]);
        // queryClient.invalidateQueries(["plan", planData["projectId"]]);
      } else {
      }
    }
  };

  const onProjectOptimisticUpdate = async (planData) => {
    const cachedData = queryClient.getQueryData(fetchPlansQueryKey);

    if (cachedData) {
      const index = cachedData.findIndex(
        (item) => item.projectId === planData.projectId
      );

      if (index !== -1) {
        let clone = JSON.parse(JSON.stringify(cachedData));
        clone[index] = planData;

        mutationCache.clear();

        queryClient.setQueryData(fetchPlansQueryKey, clone);
        queryClient.setQueryData(["plans", dates], clone);
        queryClient.setQueryData(["plan", planData["projectId"]], planData);

        // queryClient.invalidateQueries(fetchPlansQueryKey);
        // queryClient.invalidateQueries(["plans", dates]);
        // queryClient.invalidateQueries(["plan", planData["projectId"]]);
      }
    }
  };

  const onProjectUpdateSuccess = async (planData) => {
    // queryClient.invalidateQueries(fetchPlansQueryKey);
    queryClient.invalidateQueries(["plans", dates]);
    queryClient.invalidateQueries(["plan", planData["projectId"]]);
  };

  const onFailedProjectUpdate = () => {
    queryClient.invalidateQueries(fetchPlansQueryKey);
  };

  const handleDragDropMachineList = async (currentOrder) => {
    if (
      currentOrder &&
      JSON.stringify(currentOrder) !==
        JSON.stringify(Array.from(Array(filteredTargetData.length).keys()))
    ) {
      const originOrder = filteredTargetData.map((machine, index) => ({
        [index]: machine.sortIndex,
      }));
      const newOrder = currentOrder.map((order, index) => ({
        [order]: originOrder[index][index],
      }));
      const newMachineList = [];
      const tempSortIndexList = [];
      let hasKeyDupplicate = false;
      newOrder.forEach((item) => {
        tempSortIndexList.push(item[Object.keys(item)[0]]);
      });
      for (const index of tempSortIndexList) {
        if (tempSortIndexList.filter((item) => item === index).length > 1) {
          hasKeyDupplicate = true;
          break;
        }
      }
      if (hasKeyDupplicate) {
        dispatch(saveMessage("Saving, please wait!"));
        const clonedMachinesData = machinesData
          .sort((a, b) => a.sortIndex - b.sortIndex)
          .map((item, index) => ({ ...item, newSortIndex: index }));
        for (const machine of clonedMachinesData) {
          if (machine.sortIndex !== machine.newSortIndex) {
            const { newSortIndex, ...machineData } = machine;
            machineData.sortIndex = machine.newSortIndex;
            await updateMachineService(machineData);
          }
        }
        window.location.reload();
      } else {
        for (const order of newOrder) {
          setMachineListLoading(true);
          let machineIndex = Object.keys(order)[0];
          const machineData = filteredTargetData[machineIndex];
          const machineOriginIndex = machineData.sortIndex;
          const machineNewIndex = order[machineIndex];

          if (machineOriginIndex !== machineNewIndex) {
            machineData.sortIndex = machineNewIndex;
            await updateMachineService(machineData);
          }
          newMachineList.push(machineData);
        }
      }
      setMachineListLoading(false);
    }
  };

  return (
    <div className={classNames.root}>
      {openSearchOverlay && <SearchOverlay />}
      <CRMOverlay
        refetchPlansData={refetchPlansData}
        allPlansData={allPlansData}
        dates={recalculatedDates}
        calendarStepWidth={calendarStepWidth}
        calendarStepHeight={calendarStepHeight}
      />
      <PlanOverlay />
      <ScreenSizeAndPosition>
        <PrebookingBubble />
        <PrebookingOverlay />
      </ScreenSizeAndPosition>
      <div className={classNames.leftContainer}>
        <CalendarHeaderLeft
          title="Planlæg"
          subTitle="OVERBLIK OVER DISPONERING"
        />
        <DispositionStickyControl
          initialPosition={{
            top: 190,
          }}
          scrollToPosition={{
            top: 56,
          }}
          scrollPoint={25}
          style={{
            backgroundColor: "rgb(241, 241, 241)",
            zIndex: 1000,
          }}
          backgroundStyle={{
            backgroundColor: "rgb(241, 241, 241)",
            zIndex: 999,
            width: 354,
            position: "fixed",
            height: 165,
            top: 0,
          }}
        >
          <CalendarControlsLeft calendarPage={CALENDAR_PAGE.DISPOSITION} />
        </DispositionStickyControl>
        {/* <div style={{ position: "sticky", top: 190, left: 104 }}>
        </div> */}
        {/* <div style={{ height: 135 }} /> */}
        <div className={classNames.machineContainer}>
          {filteredTargetData && displayStaffOnPlanning && staffItemContainer}
          {machineListLoading ? (
            <Spinner
              className={classNames.spinner}
              size={SpinnerSize.large}
              label="Loading..."
              labelPosition="right"
            />
          ) : (
            filteredTargetData.length > 0 &&
            !displayStaffOnPlanning && (
              <CustomDraggableList
                onDragEnd={(currentOrder) =>
                  handleDragDropMachineList(currentOrder)
                }
                maxLength={machinesData.length}
                enableDrag={allowEdit && !disableDragMachine}
                rowHeight={calendarStepHeight}
              >
                {filteredTargetData.map((item, index) => (
                  <ScreenSizeAndPosition
                    key={displayStaffOnPlanning ? item.userId : item.machineId}
                  >
                    <ItemContainer
                      calendarPage={CALENDAR_PAGE.DISPOSITION}
                      workingRole={item.workingRole}
                      machineDocumentUrl={item.machineDocumentUrl}
                      index={index}
                      name={displayStaffOnPlanning ? `` : item.machineName}
                      machineId={item.machineId}
                      techDepartments={item.techDepartments}
                      techAreas={item.techAreas}
                      imageUrl={item.imageUrl}
                      color={item.color}
                      machineData={item}
                      updateMachineService={updateMachineService}
                      isPlanningPage
                      setDisableDragMachine={setDisableDragMachine}
                      unplannedItems={unplannedItems.map((machine) => ({
                        ...machine,
                        plan: allPlansData[machine["planIndex"]],
                      }))}
                      isUpdated={getProjectUpdate}
                      machineBarList={
                        verticalPositionMap["positionsToIds"][
                          calendarStepHeight * index
                        ]
                      }
                      horizontalPositionMap={horizontalPositionMap}
                      calendarStart={calendar.start}
                      calendarEnd={calendar.end}
                    />
                  </ScreenSizeAndPosition>
                ))}
              </CustomDraggableList>
            )
          )}
        </div>
      </div>
      <div>
        <ScreenSizeAndPosition>
          <CalendarHeaderRight />
          <CalendarControlsRight
            onClickChooseDate={onClickChooseDate}
            calendarPage={CALENDAR_PAGE.DISPOSITION}
          />
        </ScreenSizeAndPosition>
        <div className={classNames.stickyHeader}>
          <div style={{ height: 60 }} />
          <div className={classNames.machineAndCalendarContainer}>
            <div className={classNames.calendarContainer}>
              {displayWeekView && <div style={{ height: 54 }} />}
              <div className={classNames.weeks}>
                {recalculatedDates.length &&
                  getMonthIndicator(recalculatedDates).map(
                    (monthData, indexMonth) => (
                      <MonthLabel
                        isWeekView={displayWeekView}
                        index={indexMonth}
                        key={
                          indexMonth + monthData["length"] + monthData["label"]
                        }
                        length={monthData["length"]}
                        label={monthData["label"]}
                      />
                    )
                  )}
              </div>

              <div
                className={classNames.weeks}
                style={{
                  marginLeft:
                    calendarStartFrom === "month" &&
                    !displayWeekView &&
                    Array.isArray(recalculatedDates) &&
                    recalculatedDates[0]?.length < 7
                      ? recalculatedDates[0].length * CALENDAR_STEP_WIDTH
                      : 0,
                }} // Calculate position for first week
              >
                {recalculatedDates.length > 0 &&
                  removeFirstWeek(
                    recalculatedDates,
                    calendarStartFrom === "month" &&
                      !displayWeekView &&
                      Array.isArray(recalculatedDates) &&
                      recalculatedDates[0]?.length < 7
                  ).map((week, indexWeek) => (
                    <WeekLabel
                      isWeekView={displayWeekView}
                      index={indexWeek}
                      month={week[0]["month"]}
                      day={week[0]["date"]}
                      year={week[0]["year"]}
                      key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
                    />
                  ))}
              </div>
              {!displayWeekView && (
                <>
                  <div className={classNames.days}>
                    {recalculatedDates.length > 0 &&
                      recalculatedDates.map((week) =>
                        week.map((each) => (
                          <DayLabel
                            label={each.date}
                            date={each}
                            key={JSON.stringify(each)}
                          />
                        ))
                      )}
                  </div>
                  <div className={classNames.dayOfWeeks}>
                    {recalculatedDates.length > 0 &&
                      recalculatedDates.map((week) =>
                        week.map((each) => (
                          <DayLabel
                            label={each.date}
                            date={each}
                            key={JSON.stringify(each)}
                            isDaysOfWeek
                          />
                        ))
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={classNames.machineAndCalendarContainer}>
          <div className={classNames.calendarContainer}>
            <div
              style={{
                minHeight: "calc(100vh - 305px)",
                height: heightOfBackgroundRef.current,
                width: calendarStepWidth * 7 * recalculatedDates.length - 24,
                position: "absolute",
                backgroundImage: displayWeekView
                  ? 'url("./img/calendarBackground5.png")'
                  : 'url("./img/calendarBackground3.png")',
                backgroundRepeat: "repeat",
                backgroundPositionX:
                  calendarStartFrom === "month" &&
                  !displayWeekView &&
                  Array.isArray(recalculatedDates) &&
                  recalculatedDates[0]?.length < 7
                    ? recalculatedDates[0].length * CALENDAR_STEP_WIDTH
                    : 0,
              }}
            >
              <div
                id="planContainer"
                className="planContainer"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: heightOfBackgroundRef.current,
                  overflowY: "clip",
                }}
              >
                {barDrawer && (
                  <BarDrawer
                    calendarStepWidth={calendarStepWidth}
                    calendarStepHeight={calendarStepHeight}
                    horizontalPositions={horizontalPositionMap}
                    verticalPositions={verticalPositionMap}
                    calendarStart={calendar.start}
                    calendarEnd={calendar.end}
                    techDepartment={techDepartment}
                    machinesData={machinesData}
                    staffsData={staffsData}
                    staffsWithPosition={staffsWithPosition}
                    displayStaffOnPlanning={displayStaffOnPlanning}
                    onInvalidatePlansQuery={() => {
                      queryClient.invalidateQueries(fetchPlansQueryKey);
                      // queryClient.clear();
                    }}
                    renderDependencies={renderDependencies}
                    optimisticUpdateProject={onProjectDrawOptimistic}
                  />
                )}
                {!displayStaffOnPlanning &&
                  Array.isArray(prebookingsData) &&
                  prebookingsData
                    .filter((prebooking) =>
                      prebooking.projectName
                        .toLowerCase()
                        .includes(projectNames?.toLowerCase() || "")
                    )
                    .map((prebooking, index) => (
                      <Prebooking
                        key={`${prebooking["id"]}-${index}`}
                        prebooking={prebooking}
                        calendarStepWidth={calendarStepWidth}
                        start={prebooking["start"]}
                        end={prebooking["end"]}
                        horizontalPositions={horizontalPositionMap}
                        verticalPositions={verticalPositionMap}
                        calendarStart={calendar.start}
                        calendarEnd={calendar.end}
                        renderDependencies={renderDependencies}
                      />
                    ))}
                {!displayStaffOnPlanning &&
                  planPlaceholder.active &&
                  planPlaceholderList.map((plan) => (
                    <PlanPlaceholder
                      key={plan.id}
                      machine={plan.machine}
                      calendarStepWidth={calendarStepWidth}
                      start={plan["start"]}
                      end={plan["end"]}
                      horizontalPositions={horizontalPositionMap}
                      verticalPositions={verticalPositionMap}
                      calendarStart={calendar.start}
                      calendarEnd={calendar.end}
                      renderDependencies={renderDependencies}
                    />
                  ))}
                {!displayStaffOnPlanning &&
                  horizontalPositionMap &&
                  verticalPositionMap &&
                  Array.isArray(plansData) &&
                  plansData.map(
                    (plan) =>
                      plan["machineRequirements"] &&
                      (!plan["inactive"] || showInactiveProject) &&
                      transformedSelectedRegion?.includes(plan.section) &&
                      plan["machineRequirements"].map(
                        (machineRequirement, machineRequirementIndex) =>
                          machineRequirement["machines"] &&
                          machineRequirement["machines"].map(
                            (machine, machineIndex) => {
                              let param =
                                plan["projectId"] +
                                machine["machineId"] +
                                machineIndex +
                                "" +
                                machineRequirementIndex;
                              return (
                                machine["machineId"] && (
                                  <Fragment key={param}>
                                    <Project
                                      hidden={
                                        machineRequirement.crmpaaFleetplanner &&
                                        machineRequirement.crmpaaFleetplanner !==
                                          CRM_DK
                                      }
                                      machines={machinesData}
                                      // key={plan["projectId"] + machine["machineId"] + machineIndex}
                                      machineRequirementIndex={
                                        machineRequirementIndex
                                      }
                                      machineIndex={machineIndex}
                                      plan={plan}
                                      machine={machine}
                                      machineId={machine["machineId"]}
                                      start={machine["start"]}
                                      end={machine["end"]}
                                      calendarStepWidth={calendarStepWidth}
                                      horizontalPositions={
                                        horizontalPositionMap
                                      }
                                      activities={
                                        plan?.machineRequirements[
                                          machineRequirementIndex
                                        ].activities
                                      }
                                      verticalPositions={verticalPositionMap}
                                      calendarStart={calendar.start}
                                      calendarEnd={calendar.end}
                                      id={`project-${param}`}
                                      handleModal={(data) =>
                                        getModal(
                                          typeof data === "string"
                                            ? data
                                            : param
                                        )
                                      }
                                      machineTeamList={machineTeamList}
                                      isUpdated={() => getProjectUpdate(param)}
                                      techDepartment={techDepartment}
                                      allPlansData={allPlansData}
                                      dates={recalculatedDates}
                                      optimisticUpdate={
                                        onProjectOptimisticUpdate
                                      }
                                      onSuccess={onProjectUpdateSuccess}
                                      renderDependencies={renderDependencies}
                                      createPlanMutation={createPlanMutation}
                                      updateMachineRequirementMutation={
                                        updateMachineRequirementMutation
                                      }
                                    />
                                    {showModal === param ||
                                    projectUpdate === `${plan["projectId"]}` ? (
                                      <TeamConfirmationDialog
                                        // key={plan["projectId"] + machine["machineId"] + machineIndex}
                                        target={`#project-${param}`}
                                        toggleTeaching={() => hideModal()}
                                        machineTeamList={machineTeamList}
                                        machine={machine}
                                        plan={plan}
                                        machineRequirementIndex={
                                          machineRequirementIndex
                                        }
                                        techDepartment={techDepartment}
                                        currentUserId={currentUserId}
                                      />
                                    ) : null}
                                  </Fragment>
                                )
                              );
                            }
                          )
                      )
                  )}
                {!displayStaffOnPlanning &&
                  Array.isArray(allPlansData) &&
                  Array.isArray(unplannedItems) &&
                  unplannedItems.map((machine, index) => (
                    <Project
                      id={`${machine.projectId}-${machine["machineRequirementIndexInPlan"]}`}
                      key={`${machine["projectId"]}${machine["position"]}-${index}`}
                      calendarStepWidth={calendarStepWidth}
                      plan={allPlansData[machine["planIndex"]]}
                      machine={machine}
                      machineId={null}
                      machineRequirementIndex={
                        machine["machineRequirementIndexInPlan"]
                      }
                      machines={machinesData}
                      machineIndex={0}
                      start={machine["start"]}
                      end={machine["end"]}
                      horizontalPositions={horizontalPositionMap}
                      verticalPositions={verticalPositionMap}
                      calendarStart={calendar.start}
                      calendarEnd={calendar.end}
                      isUpdated={() =>
                        getProjectUpdate(`${machine["projectId"]}`)
                      }
                      optimisticUpdate={onProjectOptimisticUpdate}
                      onFailed={onFailedProjectUpdate}
                      onSuccess={onProjectUpdateSuccess}
                      hidden
                      renderDependencies={renderDependencies}
                      createPlanMutation={createPlanMutation}
                      updateMachineRequirementMutation={
                        updateMachineRequirementMutation
                      }
                    />
                  ))}
                {!displayStaffOnPlanning &&
                  displayMachineServiceOnPlanning &&
                  horizontalPositionMap &&
                  verticalPositionMap &&
                  Array.isArray(filteredTargetData) &&
                  filteredTargetData.map(
                    (machine) =>
                      machine.machineService &&
                      machine?.machineService.length > 0 &&
                      machine.machineService.map((item, index) => (
                        <MachineServiceItem
                          calendarStepWidth={calendarStepWidth}
                          // viewOnly
                          key={`${machine.machineId}-${index}`}
                          machineServiceIndex={index}
                          machineService={item}
                          machine={machine}
                          machines={filteredTargetData}
                          machineId={machine.machineId}
                          start={item["start"]}
                          end={item["end"]}
                          horizontalPositions={horizontalPositionMap}
                          verticalPositions={verticalPositionMap}
                          renderDependencies={renderDependencies}
                          calendarStart={calendar.start}
                          calendarEnd={calendar.end}
                          isPlanningPage
                          allPlansData={allPlansData}
                          staffsData={staffsData}
                          // machines={machinesData}
                        />
                      ))
                  )}
                {displayStaffOnPlanning &&
                  Object.keys(horizontalPositionMap).length > 2 &&
                  staffInMachines.map((machineContainer) => (
                    <Fragment key={`${machineContainer.machineId}`}>
                      {plansData.map(
                        (plan) =>
                          plan["machineRequirements"] &&
                          (!plan["inactive"] || showInactiveProject) &&
                          transformedSelectedRegion?.includes(plan.section) &&
                          plan["machineRequirements"].map(
                            (machineRequirement, machineRequirementIndex) =>
                              machineRequirement["machines"] &&
                              machineRequirement["machines"].map(
                                (machine, machineIndex) => {
                                  return (
                                    machine["machineId"] ===
                                      machineContainer.machineId && (
                                      <StaffBar
                                        key={`${machine.machineId}-${machineIndex}-${machineRequirementIndex}`}
                                        calendarStepWidth={calendarStepWidth}
                                        staff={{
                                          ...machine,
                                          projectId: plan.projectId,
                                          projectNo: plan.projectNo,
                                          projectName: plan.projectName,
                                          color: plan.color,
                                          indexMachineRequirement:
                                            machineRequirementIndex,
                                          personResponsbible:
                                            plan.personResponsbible?.name,
                                        }}
                                        horizontalPositions={
                                          horizontalPositionMap
                                        }
                                        verticalPositions={verticalPositionMap}
                                        calendarStart={calendar.start}
                                        calendarEnd={calendar.end}
                                        calendarPage={CALENDAR_PAGE.DISPOSITION}
                                        machineY={machineContainer.y}
                                        type="machine"
                                        inactive={plan.inactive}
                                      />
                                    )
                                  );
                                }
                              )
                          )
                      )}
                      {(machineContainer?.machineService || []).map(
                        (machineService, index2) => (
                          <MachineServiceItemMini
                            key={`machineService-${index2}`}
                            machineService={machineService}
                            verticalPositions={verticalPositionMap}
                            calendarStepWidth={calendarStepWidth}
                            yPosition={machineContainer.y}
                            calendarStart={calendar.start}
                            calendarEnd={calendar.end}
                            horizontalPositions={horizontalPositionMap}
                            renderDependencies={renderDependencies}
                          />
                        )
                      )}
                      {prebookingsData
                        .filter((prebooking) =>
                          prebooking.projectName
                            .toLowerCase()
                            .includes(projectNames?.toLowerCase() || "")
                        )
                        .map(
                          (prebooking, index) =>
                            prebooking["machineId"] ===
                              machineContainer.machineId && (
                              <StaffBar
                                key={`${prebooking["id"]}-${index}`}
                                calendarStepWidth={calendarStepWidth}
                                staff={{
                                  ...prebooking,
                                  color: "242, 135, 5",
                                  personResponsbible:
                                    prebooking.personResponsbible?.name,
                                }}
                                horizontalPositions={horizontalPositionMap}
                                verticalPositions={verticalPositionMap}
                                calendarStart={calendar.start}
                                calendarEnd={calendar.end}
                                calendarPage={CALENDAR_PAGE.DISPOSITION}
                                machineY={machineContainer.y}
                                type="prebooking"
                              />
                            )
                        )}
                      {machineContainer.staffs.map((currentStaff) => {
                        const matchedStaffPlans = filteredStaffs.filter(
                          (item) => item.id === currentStaff.id
                        );
                        return matchedStaffPlans?.map((staff) => {
                          let currentIndexValue =
                            verticalPositionMap[staff.id][staff.index];
                          const minIndexValue = Math.min(
                            ...Object.values(
                              verticalPositionMap[staff.id]
                            ).filter((el) => typeof el === "number")
                          );

                          if (!currentIndexValue) {
                            currentIndexValue = minIndexValue;
                          }

                          const staffY =
                            currentStaff.y +
                            (currentIndexValue - minIndexValue) *
                              calendarStepHeight;

                          if (
                            machineContainer.machineId !== staff.machineId &&
                            showStaffInMachines
                          ) {
                            return <></>;
                          }

                          return (
                            <Fragment key={`${staff.id}-${staff.index}`}>
                              {staff.start && staff.end && (
                                <StaffBar
                                  calendarStepWidth={calendarStepWidth}
                                  staff={staff}
                                  horizontalPositions={horizontalPositionMap}
                                  verticalPositions={verticalPositionMap}
                                  calendarStart={calendar.start}
                                  calendarEnd={calendar.end}
                                  calendarPage={CALENDAR_PAGE.DISPOSITION}
                                  inactive={staff.inactive}
                                  staffY={staffY}
                                />
                              )}
                              {staff?.starfVacations?.length > 0 &&
                                staff.starfVacations.map((item, index2) => (
                                  <StaffVacationItem
                                    key={`${staff.id}-${index2}`}
                                    calendarPage={CALENDAR_PAGE.DISPOSITION}
                                    calendarStepWidth={calendarStepWidth}
                                    viewOnly
                                    staffVacationIndex={index2}
                                    staffVacation={item}
                                    staff={staff}
                                    userId={staff.id}
                                    start={item["start"]}
                                    end={item["end"]}
                                    horizontalPositions={horizontalPositionMap}
                                    verticalPositions={verticalPositionMap}
                                    calendarStart={calendar.start}
                                    calendarEnd={calendar.end}
                                    staffY={staffY}
                                    staffFullData={staffsData.find(
                                      (item) => item.userId === staff.id
                                    )}
                                    renderDependencies={renderDependencies}
                                  />
                                ))}
                              {staff?.serviceSchedules?.length > 0 &&
                                staff.serviceSchedules.map((item, index2) => (
                                  <StaffMachineServiceItem
                                    key={`${staff.id}-machine-service-${index2}`}
                                    calendarPage={CALENDAR_PAGE.DISPOSITION}
                                    calendarStepWidth={calendarStepWidth}
                                    viewOnly
                                    staffVacationIndex={index2}
                                    userMachineService={item}
                                    serviceText={getServiceText({
                                      comment: item.comment,
                                      reason: item.reason,
                                    })}
                                    staff={staff}
                                    userId={staff.id}
                                    start={item["start"]}
                                    end={item["end"]}
                                    horizontalPositions={horizontalPositionMap}
                                    verticalPositions={verticalPositionMap}
                                    calendarStart={calendar.start}
                                    calendarEnd={calendar.end}
                                    staffY={staffY}
                                    staffFullData={staffsData.find(
                                      (item) => item.userId === staff.id
                                    )}
                                    renderDependencies={renderDependencies}
                                  />
                                ))}
                            </Fragment>
                          );
                        });
                      })}
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  createPlan,
  updatePlan,
})(DispositionCalendar);
