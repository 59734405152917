import { DefaultButton, IconButton } from "@fluentui/react/";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Label } from "@fluentui/react/lib/Label";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
  faCheck,
  faLink,
  faPlus,
  faSync,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { faExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { saveMessage } from "../../redux/message/message.actions";
import { createPlan, getPlan } from "../../redux/plan/plan.actions";
import { getProject } from "../../redux/project/project.actions";
import { getLastCRMCall, syncCRM } from "../../redux/user/user.actions";
import {
  noneValue,
  STORAGE_EVT_KEY_UPDATE_PLAN,
  techDepartmentOptions,
} from "../../utils/constants";
import {
  crmLink,
  formatDate,
  getReqCrmLink,
  openInNewTab,
} from "../../utils/utils";
import { MachineRequirementsDateDisplay, TooltipForText } from "../common";
import { buttonStyle, crmButtonStyle } from "../common/calendar/ButtonStyles";
import CalendarCustom from "../common/CalendarCustom";
import MachineRequirementsControls from "./projects/MachineRequirementsControls";

const AddMachineRequirements = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { syncProcess, syncLastCall } = useSelector((state) => state.app);
  const defaultData = useSelector((state) => state.defaultData);
  const user = useSelector((state) => state.user?.user);
  const { machineTypes, sizeGroupOptions, activitiesOptions } = defaultData;
  const allowEdit = true; // Allow all users to edit
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [isCrmProject, setIsCrmProject] = useState(false);
  const [projectIdWithCrm, setProjectIdWithCrm] = useState(projectId);
  const [loadInitialQuery, setLoadInitialQuery] = useState(false);

  const { data: plansData, isSuccess: plansStatusSuccess, refetch: refetchPlans } = useQuery(
    ["plan", projectIdWithCrm, syncProcess],
    () => dispatch(getPlan(projectIdWithCrm)),
    {
      enabled: loadInitialQuery,
    }
  );

  const { data: projectData, isSuccess: projectStatusSuccess } = useQuery(
    ["project", projectId, syncProcess],
    () => dispatch(getProject(projectId))
  );

  const [editingIndex, setEditingIndex] = useState(null);
  const [machineRequirements, setMachineRequirements] = useState([]);
  const [editingRequirement, setEditingRequirement] = useState(null);
  const classNames = mergeStyleSets({
    container: {
      minHeight: "100vh",
      background: "#F1F1F1",
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    labelHeader: {
      fontSize: 11,
      lineHeight: 11,
      textTransform: "uppercase",
    },
    label: {
      marginTop: 10,
      fontSize: 24,
      lineHeight: 29,
    },
    header: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      padding: "136px 124px 30px 118px",
    },
    addNew: {
      width: 1203,
      margin: "0px auto 78px auto",
      // alignItems: 'center'
    },
    input: {
      // marginBottom: 24,
    },
    pageInnerContainer: {
      maxWidth: isCrmProject ? 1500 : 1300,
      width: "100%",
      margin: "0 auto",
    },
    tableContainer: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tr: {
      textAlign: "left",
      height: 55,
      borderBottom: "1px solid #DBDBDB",
    },
    td: {
      whiteSpace: "noWrap",
      padding: "0 20px",
    },
    th: {
      padding: "0 20px",
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    icon: {
      fontSize: 22,
      color: "rgb(0, 108, 173)",
      cursor: "pointer",
      marginRight: 12,
    },
    spin: {
      animation: "spin 2s linear infinite",
    },
    iconColumn: {
      width: 40,
      textAlign: "center",
      padding: "0 20px",
    },
    textAreaInput: {
      marginRight: 10,
      borderRadius: 5,
      width: "95%",
    },
  });

  const dropDownStyles = {
    dropdownItem: {
      height: 48,
    },
    dropdownItemSelected: {
      height: 48,
    },
    caretDown: {
      marginTop: 8,
      marginRight: 14,
      
    },
    title: {
      height: 48,
      lineHeight: 48,
      border: "none",
      padding: "none",
    },
    dropdown: {
      width: "100%",
      caretDownWrapper: {
        marginTop: 8,
      },
    },
    subComponentStyles: {
      label: {
        root: {
          fontSize: "11px",
          position: "absolute",
          zIndex: 1,
          transform: "translate(9px, -1px) scale(1)",
        },
      },
    },
  };

  useEffect(() => {
    // null means either the sync process has ended or the page just loaded
    if (syncProcess === null) {
      dispatch(getLastCRMCall());
    }
  }, [dispatch, syncProcess]);

  useEffect(() => {
    if (plansStatusSuccess) {
      setMachineRequirements(plansData?.machineRequirements.filter((req)=>!req.inactive) || []);
    }
  }, [plansStatusSuccess, plansData?.machineRequirements, syncProcess]);

  useEffect(() => {
    if (projectStatusSuccess) {
      if (projectData?.crmProjectId && projectData?.projectId) {
        setProjectIdWithCrm(projectData.projectId);
        setIsCrmProject(true);
      }
      setLoadInitialQuery(true);
    }
  }, [projectStatusSuccess, projectData?.crmProjectId, projectData?.projectId]);

  const refetchProjectPlans = ()=>{
      queryClient.invalidateQueries({queryKey: ["plan", projectIdWithCrm, syncProcess]});
      refetchPlans();
  }

  const mutationCreatePlan = useMutation(
    (project) => dispatch(createPlan(project)),
    {
      onSuccess: (data, variables) => {
        // The ReplacePreBooking page is listening to the storage for the key STORAGE_EVT_KEY_UPDATE_PLAN
        if (variables.projectId) {
          localStorage.setItem(
            STORAGE_EVT_KEY_UPDATE_PLAN,
            JSON.stringify({ id: variables.projectId })
          );
        }
        queryClient.invalidateQueries("projects");
      },
    }
  );

  const isUserFunktionær = ()=>{
    // If the user does not have the disponent role
    // And is a Funktionær
    return !user?.workingRole.includes('3') && user?.workingRole.includes('4');
  }

  const canAddRequirement = ()=>{
    /* User must be a disponent in other to add new requirements */
    return allowEdit && user?.workingRole.includes('3');
  }

  // Funktionær can only edit the requirement within the start and end period
  const canEditRequirement = ({reqCrmLink}) => {
    if((isUserFunktionær() && reqCrmLink) || user?.workingRole.includes('3')){
      return allowEdit && true;
    }

    return false;
  }

  const savePlan = (machineRequirements) => {
    const plan = {
      projectId: projectIdWithCrm,
      color: projectData.color,
      machineRequirements,
      start: projectData.start,
      end: projectData.end,
    };
    mutationCreatePlan.mutate(plan);
  };

  const add = () => {
    if (editingIndex !== null) {
      dispatch(
        saveMessage(
          "You must complete the editing process before create new one."
        )
      );
    } else {
      const [start, end] = formatDate([new Date(), new Date()]);
      const newIndex = machineRequirements.length;
      const newRequirement = {
        machineType: null,
        techDepartment: null,
        sizeGroup: null,
        crmActivities: null,
        isHidden: projectData.isQuotation,
        start,
        end,
        machines: [],
        drivers: [],
        workers: [],
        managers: [],
      };

      setMachineRequirements((values) => [...values, newRequirement]);
      setEditingIndex(newIndex);
      setEditingRequirement(newRequirement);
    }
  };

  const remove = (index) => {
    setMachineRequirements((value) =>
      value.filter(function (value, i, arr) {
        return i !== index;
      })
    );
  };

  const onDelete = (index) => {
    const machineRequirementsClone = machineRequirements.filter(
      (item, i) => i !== index
    );
    setMachineRequirements(machineRequirementsClone);
    savePlan(machineRequirementsClone);
  };

  const setEdit = (index) => {
    if (editingIndex !== null && index !== null) {
      dispatch(saveMessage("You can only edit one item at a time!"));
    } else {
      setEditingIndex(index);
      setEditingRequirement(machineRequirements[index]);
    }
  };

  const onApplyChange = () => {
    const missingInfo =
      !techDepartmentOptions.find(
        (item) => item.key === editingRequirement.techDepartment
      ) ||
      // !activitiesOptions
      //     .find((activity) => activity.id === editingRequirement.techDepartment)
      //     ?.options?.find((item) => item.key === parseInt(editingRequirement.crmActivities)) ||
      // !machineTypes.find((type) => type.id === editingRequirement.machineType) ||
      editingRequirement.crmActivities === null ||
      // editingRequirement.machineType === null ||
      // !sizeGroupOptions.find(
      //   (sizeGroup) => sizeGroup.key === editingRequirement.sizeGroup
      // ) ||
      new Date(editingRequirement.start).getTime() < 0 ||
      new Date(editingRequirement.end).getTime() < 0;
    if (missingInfo) {
      dispatch(saveMessage("Please provide missing information!"));
    } else {
      const machineRequirementsClone = machineRequirements;
      machineRequirementsClone[editingIndex] = editingRequirement;
      setMachineRequirements(machineRequirementsClone);
      setEditingIndex(null);
      setEditingRequirement(null);
      savePlan(machineRequirementsClone);
    }
  };

  const onGoBack = () => {
    if (history.action !== "REPLACE" && history.action !== "POP") {
      history.goBack();
    } else {
      history.push("/projects");
    }
  };

  return (
    <div className={classNames.container}>
      <div className={classNames.header}>
        <IconButton
          style={{
            position: "absolute",
            left: document.documentElement.offsetWidth < 1100 ? 40 : 100,
          }}
          iconProps={{ iconName: "ChromeBack" }}
          onClick={onGoBack}
        />
        <div className={classNames.labelContainer}>
          <div className={classNames.labelHeader}>
            {projectStatusSuccess &&
              (projectData
                ? "Tilføj krav til maskiner for"
                : "Tilføj maskinkrav til maskine")}
          </div>
          <Label className={classNames.label}>
            {projectStatusSuccess &&
              (projectData?.projectName
                ? `${projectData?.projectName} projekt`
                : "Vi kan ikke finde projektet!")}
            {projectData?.crmProjectId && (
              <a href={crmLink(projectData.crmProjectId,projectData.category)} rel="noreferrer"  target="_blank">
                <FontAwesomeIcon
                  icon={faLink}
                  // onClick={() => openInNewTab(crmLink(projectData.crmProjectId))}
                  style={{
                    color: "#006CAD",
                    marginLeft: 10,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                />
              </a>
            )}
          </Label>
        </div>
        <div
          style={{
            position: "absolute",
            right: document.documentElement.offsetWidth < 1100 ? 40 : 100,
            display: "flex",
            flexDirection: "column",
            alignItems: " center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "4px",
            }}
          >
            <TooltipForText text="CRM Sync" noWidth positionAbsolute>
              <DefaultButton
                id="crmSync"
                styles={{
                  ...crmButtonStyle(false),
                  root: {
                    ...crmButtonStyle(false).root,
                    margin: "auto auto",
                  },
                }}
                onClick={() => {
                  if(editingIndex === null){
                    dispatch(syncCRM(refetchProjectPlans));
                  }
                  else{
                    dispatch(
                      saveMessage(
                        "You must complete the editing process before pressing sync."
                      )
                    );
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faSync}
                  className={`${syncProcess && classNames.spin}`}
                  style={{
                    marginRight: 8
                  }}
                />
                Sync CRM
              </DefaultButton>
            </TooltipForText>
            <div style={{ textAlign: "center" }}>
              {syncLastCall && (
                <>
                  <p style={{ margin: 5 }}>Last sync:</p>
                  <p
                    style={{
                      color: "#006CAD",
                      fontWeight: 400,
                      fontSize: 12,
                      margin: 5,
                    }}
                  >
                    {syncLastCall}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {!projectData ? (
        projectStatusSuccess && (
          <div
            className={classNames.pageInnerContainer}
            style={{ textAlign: "center" }}
          >
            <div style={{ fontWeight: "bold" }}>Tjekliste:</div>
            <li style={{ marginTop: 10 }}>
              Husk aktiviteten er ikke synlig før reserveret "DK" er aktiveret i
              CRM
            </li>
            <li style={{ marginTop: 10 }}>
              Husk at projektets slutdato ikke må være ældre end 1 måned fra
              dagsdato i CRM
            </li>
          </div>
        )
      ) : (
        <div className={classNames.pageInnerContainer}>
          <div style={{ fontWeight: "bold" }}>
            Sagsansvarlig:{" "}
            <span style={{ fontWeight: "normal" }}>
              {projectData?.personResponsbible?.name || "Unknown"}
            </span>
          </div>
          <div style={{ fontWeight: "bold" }}>Projekt beskrivelse</div>
          <div
            style={{
              margin: "10px auto 10px auto",
              textAlign: "left",
            }}
          >
            {projectData?.projectDescription || "No description"}
          </div>
          <div
            style={{ fontWeight: "bold", marginBottom: 70, display: "flex" }}
          >
            Produktion periode: &ensp;{" "}
            <MachineRequirementsDateDisplay
              start={projectData?.start}
              end={projectData?.end}
            />
          </div>

          <div className={classNames.tableContainer}>
            <table className={classNames.table}>
              <tbody>
                <tr className={classNames.tr}>
                  <th className={classNames.th}></th>
                  <th className={classNames.th}></th>
                  <th className={classNames.th}>Fagsektion</th>
                  <th className={classNames.th}>Aktivitet</th>
                  <th className={classNames.th}>Ansvarlig</th>
                  <th className={classNames.th}>Hovedanlægstype</th>
                  <th className={classNames.th}>Satsgruppe</th>
                  <th className={classNames.th}>Note</th>
                  <th className={classNames.th}>Period</th>
                  {allowEdit && <th className={classNames.th}>Action</th>}
                </tr>
                {machineRequirements.map((req, index) => {
                  let isCrmReq = !!req.crmActivitiesId;
                  const currentReqCrmLink =
                    editingIndex === index
                      ? editingRequirement.crmActivitiesId
                      : req.crmActivitiesId;
                  const reqCrmLink = isCrmReq
                    ? getReqCrmLink(currentReqCrmLink)
                    : null;

                  const techDepartment =
                    editingIndex === index && !isUserFunktionær() ? (
                      <Dropdown
                        selectedKey={editingRequirement.techDepartment}
                        options={techDepartmentOptions}
                        onChange={(event, item) => {
                          setEditingRequirement((values) => ({
                            ...values,
                            techDepartment: item.key,
                          }));
                          if (item.key !== editingRequirement.techDepartment) {
                            setEditingRequirement((values) => ({
                              ...values,
                              machineType: null,
                            }));
                            setEditingRequirement((values) => ({
                              ...values,
                              crmActivities: null,
                            }));
                          }
                        }}
                        styles={dropDownStyles}
                        errorMessage={undefined}
                        className={classNames.input}
                      />
                    ) : (
                      techDepartmentOptions.find(
                        (techDepartment) =>
                          techDepartment.key === req.techDepartment
                      )?.text ?? ""
                    );
                  const crmActivities =
                    editingIndex === index && !isUserFunktionær() ? (
                      <Dropdown
                        selectedKey={parseInt(editingRequirement.crmActivities)}
                        options={
                          activitiesOptions.find(
                            (activity) =>
                              activity.id === editingRequirement.techDepartment
                          )?.options ?? [noneValue]
                        }
                        onChange={(event, item) =>
                          setEditingRequirement((values) => ({
                            ...values,
                            crmActivities: item.key,
                          }))
                        }
                        styles={dropDownStyles}
                        errorMessage={undefined}
                        className={classNames.input}
                      />
                    ) : (
                      activitiesOptions
                        .find((activity) => activity.id === req.techDepartment)
                        ?.options.find(
                          (activity) =>
                            activity.key === parseInt(req.crmActivities)
                        )?.text ?? "None"
                    );
                  const crmAnsvarligName =
                    (editingIndex === index && !isUserFunktionær()
                      ? editingRequirement.crmAnsvarlig
                      : req.crmAnsvarlig) || "None";
                  const machineType =
                    editingIndex === index && !isUserFunktionær() ? (
                      <Dropdown
                        selectedKey={editingRequirement.machineType}
                        options={
                          editingRequirement.techDepartment
                            ? defaultData?.machineTypeOptions.find(
                                (item) =>
                                  item.id === editingRequirement.techDepartment
                              )?.options ?? [noneValue]
                            : [noneValue]
                        }
                        onChange={(event, item) =>
                          setEditingRequirement((values) => ({
                            ...values,
                            machineType: item.key,
                          }))
                        }
                        styles={dropDownStyles}
                        errorMessage={undefined}
                        className={classNames.input}
                      />
                    ) : (
                      machineTypes.find((type) => type.id === req.machineType)
                        ?.name ?? "None"
                    );
                  const sizeGroup =
                    editingIndex === index && !isUserFunktionær() ? (
                      <Dropdown
                        selectedKey={editingRequirement.sizeGroup}
                        options={defaultData?.sizeGroupOptions ?? []}
                        onChange={(event, item) =>
                          setEditingRequirement((values) => ({
                            ...values,
                            sizeGroup: item.key,
                          }))
                        }
                        styles={{ ...dropDownStyles }}
                        errorMessage={undefined}
                        className={classNames.input}
                      />
                    ) : (
                      sizeGroupOptions.find(
                        (sizeGroup) => sizeGroup.key === req.sizeGroup
                      )?.text ?? "None"
                    );
                  const note =
                    editingIndex === index && !isUserFunktionær() ? (
                      <textarea
                        className={classNames.textAreaInput}
                        value={editingRequirement.crmDescription}
                        onChange={(e) =>
                          setEditingRequirement((values) => ({
                            ...values,
                            crmDescription: e.target.value,
                          }))
                        }
                      />
                    ) : (
                      <textarea
                        className={classNames.textAreaInput}
                        style={{ background: "none", border: "none" }}
                        value={req.crmDescription}
                        readOnly
                      />
                    );
                  const period =
                    editingIndex === index ? (
                      <CalendarCustom
                        period={[
                          new Date(editingRequirement.start),
                          new Date(editingRequirement.end),
                        ]}
                        setPeriod={(period) => {
                          const [start, end] = formatDate(period);
                          setEditingRequirement((values) => ({
                            ...values,
                            start,
                            end,
                          }));
                        }}
                        isLongVersion={false}
                        styles={{
                          marginTop: 0,
                          width: 250,
                          border: "none",
                          marginLeft: -3,
                        }}
                        liteVersion
                      />
                    ) : (
                      <MachineRequirementsDateDisplay
                        start={req.start}
                        end={req.end}
                      />
                    );
                  // const bottomBorder = (value) => ({borderBottom: value === '' ? '1px solid red' : 'unset'});

                  return (
                    <tr key={index} className={classNames.tr}>
                      <td className={classNames.iconColumn}>
                        {req.hasPlanned && projectData?.projectId ? (
                          <Link to={`/planning/${projectData.projectId}/${index}`}>
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ fontSize: 24, color: "black" }}
                            />
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className={classNames.iconColumn}>
                        {isCrmReq ? (
                          reqCrmLink ? (
                            <a href={reqCrmLink}>
                              <FontAwesomeIcon
                                icon={faLink}
                                style={{ fontSize: 24, color: "black" }}
                              />
                            </a>
                          ) : (
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ fontSize: 24, color: "black" }}
                            />
                          )
                        ) : (
                          <FontAwesomeIcon
                            icon={faExclamation}
                            style={{ fontSize: 24, color: "#F57C00" }}
                          />
                        )}
                      </td>
                      <td className={classNames.td}>{techDepartment}</td>
                      <td className={classNames.td}>{crmActivities}</td>
                      <td className={classNames.td}>{crmAnsvarligName}</td>
                      <td className={classNames.td}>{machineType}</td>
                      <td className={classNames.td}>{sizeGroup}</td>
                      <td className={classNames.td}>{note}</td>
                      <td className={classNames.td}>{period}</td>
                      {allowEdit && (
                        <td className={classNames.td}>
                          {editingIndex !== null && editingIndex === index ? (
                            <div className={classNames.iconContainer}>
                              <FontAwesomeIcon
                                icon={faCheck}
                                className={classNames.icon}
                                onClick={onApplyChange}
                              />
                              <FontAwesomeIcon
                                icon={faTimes}
                                className={classNames.icon}
                                onClick={() => {
                                  setEdit(null);
                                  if (
                                    index ===
                                    projectData.machineRequirementCount
                                  )
                                    remove(index);
                                }}
                              />
                            </div>
                          ) : (
                            <>
                              {
                                canEditRequirement({
                                  reqCrmLink
                                }) &&
                                <MachineRequirementsControls
                                  index={index}
                                  edit={() => setEdit(index)}
                                  remove={onDelete}
                                  isCrmReq={isCrmReq}
                                />
                              }
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
                {canAddRequirement() && (
                  <tr className={classNames.tr}>
                    <td colSpan={9}></td>
                    <td>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={classNames.icon}
                        onClick={add}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddMachineRequirements;
